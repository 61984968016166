import React from 'react';

import Layout from '@/components/Layout/InternalPages';
import LinkGenerator from '@/components/Section/LinkGenerator';

export default function GeneratorLinkPage() {
  return (
    <Layout>
      <LinkGenerator></LinkGenerator>
    </Layout>
  );
}
